import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { MapPin } from "react-feather"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import lightenDarkenColor from "../utils/lighten-or-darken"

const container = { maxWidth: "75ch", padding: "1em", margin: "0 auto" }

export default function Homepage(props) {
  const { contentfulGenericPage } = props.data

  return (
    <Layout title={contentfulGenericPage.heading}>
      <header css={container}>
        <Link
          to="/"
          css={(theme) => ({
            background: theme.accentColor,
            color: "#fff",
            fontSize: "0.8em",
            padding: "0.5em 1em",
            borderRadius: "0.5em",
            textDecoration: "none",
            "&:hover": {
              background: lightenDarkenColor(theme.accentColor, 20),
            },
            "&:active": {
              background: lightenDarkenColor(theme.accentColor, -20),
            },
          })}
        >
          <MapPin size="0.8em" /> Back to the map
        </Link>
      </header>
      <main css={container}>
        <h1 css={(theme) => ({ color: theme.headingColor })}>
          {contentfulGenericPage.heading}
        </h1>
        {documentToReactComponents(JSON.parse(contentfulGenericPage.body.raw))}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    contentfulGenericPage(id: { eq: $id }) {
      heading
      body {
        raw
      }
    }
  }
`
